import AOS from 'aos';
import React, { Fragment } from 'react';
import { Route, Routes } from "react-router-dom";
import Academia from './pages/Academia';
import Affiliates from './pages/Affiliates';
import ArtBoard from './pages/ArtBoard';
import Careers from './pages/Careers';
import Events from './pages/Events';
import Home from './pages/Home';
import Marketplace from './pages/Marketplace';
import Contact from './sections/Contact';
import Footer from './sections/Footer';
import NavBar from './sections/NavBar';

import 'aos/dist/aos.css';
import './assets/css/main.css';
import './assets/css/plugin.css';
import './assets/css/responsive.css';
import './assets/css/slick.css';
import Policy from './pages/Policy';



AOS.init({
  duration: 1000
});

export default function App() {
  return (
    <Fragment>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />
        <Route path="/art-board" element={<ArtBoard />} />
        <Route path="/academia" element={<Academia />} />
        <Route path="/market-place" element={<Marketplace />} />
        <Route path="/affiliates" element={<Affiliates />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/policy" element={<Policy />} />
      </Routes>
      <Footer />
    </Fragment>
  )
}