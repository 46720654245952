import React from 'react';

function Policy() {
    return (
        <div className='pt-5 mt-5 text-white'>
            {/* Navigation Bar */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary py-3 shadow-sm">
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="#privacy-policy">
                                    Privacy Policy
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#terms-conditions">
                                    Terms & Conditions
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#cancellation-refund">
                                    Cancellation & Refund
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#shipping-delivery">
                                    Shipping & Delivery
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Main Content */}
            <div className="container my-5">
                {/* Privacy Policy Section */}
                <section id="privacy-policy" className="mb-5">
                    <h1 className="mb-3">Privacy Policy</h1>
                    <p className="text-secondary mb-3">
                        At ShopEase, we value your privacy and are committed to protecting your personal information. We only collect the personal information necessary for order processing and improving your shopping experience.
                    </p>
                    <p className="text-secondary mb-3">
                        We will never share your personal data unless required by law or with your consent.
                    </p>
                </section>

                {/* Terms & Conditions Section */}
                <section id="terms-conditions" className="mb-5">
                    <h1 className="mb-3">Terms & Conditions</h1>
                    <p className="text-secondary mb-3">
                        By accessing ShopEase, you agree to comply with these terms. Continued use implies agreement to any changes made to these terms.
                    </p>
                    <ul className="text-secondary">
                        <li>All orders are subject to payment confirmation and availability.</li>
                        <li>We reserve the right to cancel orders if they violate our policies.</li>
                        <li>All payments are required before shipping is processed.</li>
                    </ul>
                </section>

                {/* Cancellation & Refund Policy Section */}
                <section id="cancellation-refund" className="mb-5">
                    <h1 className="mb-3">Cancellation & Refund Policy</h1>
                    <p className="text-secondary mb-3">
                        You can cancel your order under the following conditions:
                    </p>
                    <ul className="text-secondary">
                        <li>Cancellation requests must be submitted within 14 days of purchase.</li>
                        <li>Products must be returned in their original condition and packaging.</li>
                        <li>Refunds will be processed to the original payment method within 7–10 business days upon approval.</li>
                    </ul>
                </section>

                {/* Shipping & Delivery Section */}
                <section id="shipping-delivery" className="mb-5">
                    <h1 className="mb-3">Shipping & Delivery</h1>
                    <p className="text-secondary mb-3">
                        ShopEase partners with trusted delivery providers to ensure fast and efficient delivery to your location. Most orders are delivered within 3-5 business days.
                    </p>
                    <p className="text-secondary mb-3">
                        Delivery delays can occur during holidays, inclement weather, or supply chain disruptions. Contact support for any concerns.
                    </p>
                </section>
            </div>
        </div>
    );
}

export default Policy;
