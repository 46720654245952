import React from 'react'
import About from '../sections/About'
import HeroBanner from '../sections/HeroBanner'
import Services from '../sections/Services'
import Testimonial from '../sections/Testimonial'

export default function Home() {
    window.scroll(0, 0)
    return (
        <>
            <HeroBanner />
            <About />
            <Services />
            <Testimonial />
        </>
    )
}
